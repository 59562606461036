import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAppDispatch, useAppSelector } from "./store/store";
import { shallowEqual, useSelector } from "react-redux";
import Toast from "./components/design-system/Toast";
import { toastFetched } from "./store/reducer/toastSlice";
import { useContext } from "./util/useContext";
import { isEmptyObject } from "./util/object";
import { LOCAL_STORAGE, SESSION_STORAGE } from "./lib/const";
import { signIn, signOut } from "./lib/modules/auth";
import { API_DEFAULT } from "./api/api";
import { useAxios, visitAxios } from "./lib/api/useAxios";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import LayoutComponent from "./components/layout/LayoutTemplete";
import { removeCookie, setCookie } from "./util/cookies";
import LoginAuthModal from "./components/design-system/modal/LoginAuthModal";
import {
  modalFetched,
  niceModalFetched,
  overlapModalFetched,
} from "./store/reducer/globalModalSlice";
import NiceAuthModal from "./components/design-system/modal/NiceAuthModal";
import { setStockListInit } from "./store/reducer/mainStock";
import ScrollToTop from "./components/hooks/useScrollReset";
import { useQuery } from "@tanstack/react-query";
import { uploadFlagFetched } from "./store/reducer/uploadEndSlice";
import UploadStatCheck from "./components/upload/UploadStatCheck";
//@ts-ignore
import TagManager from "react-gtm-module";
import { searchFlagFetched, setVersionData } from "./store/reducer/mobileSlice";
import { browserFetched } from "./store/reducer/browerSlice";
import OverlapModal from "./components/common/OverlapModal";
import uuid from "react-uuid";
import NoticeModal from "./components/common/NoticeModal";
import { instance } from "./api";
import { meData } from "./api/info/info";
import { setLogin, setMe } from "./store/reducer/authSlice";
import { MetaDatas } from "./meta/MetaDatas";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const location = useGeolocation();
  const navigate = useNavigate();
  const toastData = useAppSelector((state) => state.toast.data, shallowEqual);
  const modalData = useAppSelector((state) => state.modal.data, shallowEqual);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );
  const niceData = useAppSelector(
    (state) => state.modal.niceData,
    shallowEqual
  );
  const overlapData = useAppSelector(
    (state) => state.modal.overlapData,
    shallowEqual
  );
  const { searchFlag } = useSelector((state: any) => state.mobile);
  const stat = useAppSelector((state) => state.uploadEnd.stat, shallowEqual);
  const { me, isLogin } = useContext();
  const { post, del, get } = useAxios();
  const { accessToken, refreshToken, expiresAt } = useAppSelector(
    (state) => state.auth
  );
  const [refetchFlag, setRefetchFlag] = useState(false);

  useEffect(() => {
    const accessData = localStorage.getItem(LOCAL_STORAGE.TOKEN);

    if (!accessData) {
      dispatch(setLogin(false));
      dispatch(setMe({}));
    }
  }, [location, isLogin]);

  const { data: infoMe } = useQuery({
    queryKey: ["info-me", isLogin],
    queryFn: meData,
    enabled: isLogin !== false,
    refetchInterval: 1000 * 60 * 30, // 1000ms * 60초 * 30분
  });

  useEffect(() => {
    // infoMe 자체가 없는 경우(= 아직 로딩 중)는 아무 것도 안 함
    if (!infoMe?.data) return;

    dispatch(setMe(infoMe.data));

    // me 값이 없으면 로그인 페이지로 이동
    if (!infoMe.data) {
      dispatch(setLogin(false));
      navigate("/login");
    }
  }, [infoMe, dispatch, navigate]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: `GTM-KXW2QMDT`,
    });
  }, []);

  function handleInAppBrowser() {
    const userAgent = navigator.userAgent;
    if (
      /inapp|NAVER|KAKAOTALK|Snapchat|Line|Instagram|WhatsApp|Electron|FBAN|FBAV|FBIOS|FBSS|SamsungBrowser/i.test(
        userAgent
      )
    ) {
      dispatch(setVersionData(true));
    }
  }
  // 페이지 로드 시 인앱 브라우저 감지 및 처리
  window.onload = handleInAppBrowser;

  if (process.env.NODE_ENV === "production") {
    // 원본 console 메서드를 저장해 둡니다.
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    // 모든 console 메서드를 no-op(아무 동작도 안 함)으로 덮어씌웁니다.
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};

    // 딱 한 번만 출력하고 싶은 메시지는 원본 로그 함수를 사용합니다.
    originalLog(
      `%c
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣀⣀⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣿⣿⣿⣿⣿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⠀⠀⠀⢠⣿⣿⣿⡟⢹⣿⣿⣿⡄⠀⠀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⠀⠀⢠⣿⣿⣿⡟⠀⠈⣿⣿⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⠀⢠⣿⣿⣿⡟⠀⠀⠀⠘⣿⣿⣿⣷⡀⠀⠀⠀⠀⠀⠀
      ⠀⠀⠀⠀⠀⢠⣿⣿⣿⡟⠁⠀⠀⠀⠀⠘⣿⣿⣿⣿⣦⡀⠀⠀⠀⠀
      ⠀⠀⠀⠀⢠⣿⣿⣿⡿⠁⠀⠀⠀⠀⠀⠀⠈⠻⣿⣿⣿⣿⣷⡂⠀⠀
      ⠀⠀⠀⢠⣿⣿⣿⡿⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠿⣿⣿⡏⡎⡇
      ⠀⢀⡀⣄⢜⢜⠄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠀⢇⠇
      ⠀⠑⠘⠐⠑⠑⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠑⠁
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⣰⠾⢶⠰⠷⢾⣶⠷⢞⢠⣶⡆⠀⠀⣶⡄⠀⠀⢰⡂⢴⡷⠾⢶⣤⠀⠀⠀⣾⠶⣧⡀⢰⠆⠀⠀⢰⡆⢷⣄⠀⣴⠗⠀
  ⠀⠽⣦⡄⠀⠀⢸⣯⠀⠀⣾⢻⡇⠀⠀⡟⠻⣇⠀⢸⡂⢼⡂⠀⠀⢹⣗⠀⠀⣯⣤⣼⠃⢸⡃⠀⠀⢸⡇⠀⠻⡿⠃⠀⠀
  ⠀⣀⠈⢻⡆⠀⢸⣯⠀⣼⠇⠈⢿⣄⢀⡯⠀⠘⣷⣼⡂⢺⡅⠀⢀⣼⠏⠀⠀⣯⠁⢩⣷⢸⣇⠀⠀⣼⡇⠀⠀⡯⠀⠀⠀
  ⠀⠙⠞⠛⠁⠀⠘⠏⡸⠏⠀⠀⠀⠛⠳⡣⠀⠀⠀⠛⠂⠙⠗⠛⠛⠁⠀⠀⠀⠟⠟⠛⠁⠀⠙⠻⠛⠍⠀⠀⠀⠏⠀⠀⠀
    `,
      `
        font-family: sans-serif;
        text-shadow:
          3px 3px 8px rgba(0, 0, 0, 0.7),   /* 진하게 한 번 */
          6px 6px 4px rgba(255, 255, 255, 0.3); /* 흐리게 한 번 */
      `
    );
  }

  useEffect(() => {
    if (location.pathname === "/feed/filter/") {
      navigate("/feed");
    }

    dispatch(searchFlagFetched(false));
  }, [location]);

  const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

  async function refreshLogin() {
    const _token = await localStorage.getItem(LOCAL_STORAGE.TOKEN);

    const result =
      !!_token &&
      ((await signIn({
        dispatch: dispatch,
        data: { accessToken: _token },
      })) as any);
  }

  async function socialLogin(token: string) {
    const res = await post(`${API_DEFAULT}/user/auth/sign-in/success`, {
      socialToken: token,
      browser: browserData,
    });
    if (res?.success) {
      const result = (await signIn({
        dispatch: dispatch,
        data: res?.data,
      })) as any;

      if (result?.status === "fail") {
        await del(`${API_DEFAULT}/user/auth/sign-out`, {
          accessToken: accessToken,
          refreshToken: refreshToken,
          browser: browserData,
        });
        signOut({ dispatch: dispatch });
      } else {
        navigate("/", { replace: true });
      }
    } else {
      alert(JSON.stringify(res?.error, null, 1));
    }
  }

  useEffect(() => {
    refreshLogin();
  }, []);

  useEffect(() => {
    // 'socialToken' 파라미터의 존재 여부를 확인합니다.
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("socialToken");
    const hasSocialToken = new URLSearchParams(temp).has("socialToken");

    if (!!hasSocialToken) socialLogin(params?.toString() || "");
  }, [infoMe]);

  useEffect(() => {
    if (location.pathname !== "/feed") {
      dispatch(setStockListInit());
    }
  }, [location.pathname]);

  const { data, status } = useQuery({
    queryKey: ["convert-stat", stat],
    queryFn: async () =>
      await instance.get(
        `${API_DEFAULT}/contents/convert-stat?convertId=${stat}`
      ),
    enabled: stat !== "", // 조건에 따라 쿼리 활성화 여부 결정
    refetchInterval: stat !== "" ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (data?.data.data === "PROGRESSING") {
      setRefetchFlag(data?.data.data === "PROGRESSING");
    }
    if (data?.data.data === "COMPLETE") {
      setRefetchFlag(false);
      dispatch(
        toastFetched({
          show: true,
          text: "업로드를 완료 했습니다!",
          type: "",
        })
      );
      dispatch(uploadFlagFetched(""));
    }
    if (data?.data.data === "ERROR") {
      setRefetchFlag(false);
      dispatch(
        toastFetched({
          show: true,
          text: "업로드에 실패했어요. 다시 시도해주세요.",
          type: "error",
        })
      );
      dispatch(uploadFlagFetched(""));
    }
  }, [data?.data]);

  useEffect(() => {
    //*----------------------채널톡연동--------------------------*
    ChannelService.loadScript();
    // 로그인
    if (isLogin) {
      ChannelService.boot(
        {
          pluginKey: "618376fc-ab1c-42f0-85dc-a25e45c8644b", // 플러그인 키 기입, 키값은 그대로 사용해주시면 됩니다,
          memberId: me?.email, // email
          profile: {
            name: me?.name, // name
            mobileNumber: me?.phone, // phone
            birth: me?.birth, // birth
            grade: me?.grade, // grade
          },
        },
        function onBoot(error, user) {
          if (error) {
            // console.error(error);
          } else {
            // console.log('boot success', user)
          }
        }
      );
    } else {
      // 익명 유저 (로그인하지 않았을 경우)
      ChannelService.boot(
        {
          pluginKey: "618376fc-ab1c-42f0-85dc-a25e45c8644b", // 플러그인 키 기입, 키값은 그대로 사용해주시면 됩니다,
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            // console.log('boot success', user)
          }
        }
      );
    }
    //*----------------------채널톡연동종료--------------------------*
  }, [me]);

  function detectBrowser() {
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile|Android/i.test(userAgent);

    if (
      userAgent.indexOf("Chrome") > -1 &&
      userAgent.indexOf("Edge") === -1 &&
      userAgent.indexOf("OPR") === -1
    ) {
      return isMobile ? "Mobile Chrome" : "PC Chrome";
    } else if (
      userAgent.indexOf("Safari") > -1 &&
      userAgent.indexOf("Chrome") === -1
    ) {
      return isMobile ? "Mobile Safari" : "PC Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      return isMobile ? "Mobile Firefox" : "PC Firefox";
    } else if (
      userAgent.indexOf("OPR") > -1 ||
      userAgent.indexOf("Opera") > -1
    ) {
      return isMobile ? "Mobile Opera" : "PC Opera";
    } else if (
      userAgent.indexOf("Edge") > -1 ||
      userAgent.indexOf("Edg") > -1
    ) {
      return isMobile ? "Mobile Edge" : "PC Edge";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident/") > -1
    ) {
      return "Internet Explorer";
    } else {
      return "Unknown";
    }
  }

  const browserCheck = detectBrowser();

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.BROWSER, detectBrowser());
    dispatch(browserFetched(detectBrowser()));
  }, []);

  async function onVisitantFetch(browserCheck: any) {
    const res = await visitAxios.post(
      `${API_DEFAULT}/user/visit?browser=${browserCheck}`
    );
  }

  // useEffect(() => {
  //   // 1) 현재 시각에서 "시:분" 문자열을 생성한다. (초 단위 제외)
  //   const date = new Date();
  //   const hours = String(date.getHours()).padStart(2, "0"); // "00" ~ "23"
  //   const minutes = String(date.getMinutes()).padStart(2, "0"); // "00" ~ "59"
  //   const nowHM = `${hours}:${minutes}`; // "HH:MM"

  //   // 2) 저장된 "HH:MM"값과 비교
  //   const savedTime = localStorage.getItem("visitTime");

  //   // 3) 다르면 기존 id 제거 후 새 시간으로 갱신
  //   if (savedTime !== nowHM) {
  //     localStorage.removeItem("id");
  //     localStorage.setItem("visitTime", nowHM);
  //   }

  //   // 4) id 존재 여부 체크 → 없으면 새로 생성 후 onVisitantFetch 호출
  //   const existingId = localStorage.getItem("id");
  //   if (!existingId) {
  //     const newId = uuid();
  //     localStorage.setItem("id", newId);
  //     onVisitantFetch(browserCheck);
  //   }
  // }, []);

  useEffect(() => {
    // 1) 오늘 날짜를 YYYY-MM-DD 형태로 구한다.
    const today = new Date().toISOString().split("T")[0];
    const savedVisitDate = localStorage.getItem("visitDate");

    // 2) 저장된 날짜와 오늘이 다르면 => 새 날짜로 갱신 + 기존 id 제거
    if (savedVisitDate !== today) {
      localStorage.removeItem("id");
      localStorage.setItem("visitDate", today);
    }

    // 3) id 존재 여부 체크 → 없으면 새로 생성하여 onVisitantFetch 호출
    const existingId = localStorage.getItem("id");
    if (!existingId) {
      const newId = uuid();
      localStorage.setItem("id", newId);
      onVisitantFetch(browserCheck);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      if (isLogin && me?.agreementSelectiveTerms) {
        if (location.pathname === "/social-check") {
          navigate("/feed", { replace: true });
        } else {
          return;
        }
      } else if (isLogin && me?.agreementSelectiveTerms === false) {
        navigate("/social-check");
      }
    } else if (location.pathname === "/" && me?.agreementSelectiveTerms) {
      navigate("/feed", { replace: true });
    }

    if (location.pathname === "/social-check" && me?.agreementSelectiveTerms) {
      navigate("/feed");
    }
  }, [me, isLogin, location.pathname]);

  return (
    <>
      <LayoutComponent>
        <Outlet />
      </LayoutComponent>
      <ScrollToTop />

      {toastData && (
        <Toast
          type={toastData.type || "success"}
          open={toastData.show}
          text={toastData.text}
          setOpen={() => {
            dispatch(
              toastFetched({
                show: false,
                text: "",
                type: "",
              })
            );
          }}
        />
      )}
      {modalData.show && (
        <LoginAuthModal
          open={modalData.show}
          onClose={() => {
            dispatch(
              modalFetched({
                show: false,
              })
            );
          }}
        />
      )}

      {niceData.show && (
        <NiceAuthModal
          open={niceData.show}
          onClose={() => {
            dispatch(
              niceModalFetched({
                show: false,
              })
            );
          }}
        />
      )}

      {overlapData.show && (
        <OverlapModal
          open={overlapData.show}
          onClose={() => {
            dispatch(
              overlapModalFetched({
                show: false,
              })
            );
          }}
        />
      )}
    </>
  );
}

export default App;
