import { Avatar } from "@material-tailwind/react";
import Text from "../design-system/Text";
import { useContext } from "../../util/useContext";
import { checkRole } from "../../util";
import { signOut } from "../../lib/modules/auth";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { shallowEqual } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { signOutLogin } from "../../api/login/login";
import { toastFetched } from "../../store/reducer/toastSlice";
import StockText from "../design-system/StockText";
import { Link, useNavigate } from "react-router-dom";
import MobileTopSection from "./MobileTopSection";
import { useState } from "react";
import MobileAvatarModal from "./MobileAvatarModal";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { ProfileAvatarMenu } from "./ProfileAvatarMenu";
import Switcher from "./Switcher";
import { CustomImage } from "../design-system/CustomImg";
import LottieData from "../../assets/lottie/notification-V3.json";
import { alarmCheck } from "../../api/alarm/alarm";

export default function MobileBoxAvatarCard() {
  const { me, isLogin } = useContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mobileModal, setMobileModal] = useState({ show: false });
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navList = (
    <div className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-10">
      <Switcher />

      <div className="hover:text-[#42BC7F] subtitle-3">
        <Link to="/location-feed">
          <Text className={""} fontWeight={500}>
            지도로 찾기
          </Text>
        </Link>

        {/* <Text
          className={" cursor-pointer"}
          fontWeight={500}
          onClick={() => {
            setInfoModal({ show: true });
          }}
        >
          지도로 찾기
        </Text> */}
      </div>

      {isLogin ? (
        <div className="hover:text-[#42BC7F] subtitle-3  ">
          <Link to="/cart">
            <Text className={"click-a "} fontWeight={500}>
              장바구니
            </Text>
          </Link>
        </div>
      ) : (
        <span
          className="flex items-center subtitle-3  cursor-pointer"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            }
          }}
        >
          장바구니
        </span>
      )}

      {isLogin && me?.mobileVerification ? (
        <div className="hover:text-[#42BC7F] subtitle-3 ">
          <Link to="/upload">
            <Text fontWeight={500}>업로드</Text>
          </Link>
        </div>
      ) : (
        <span
          className="flex items-center subtitle-3  cursor-pointer"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else if (isLogin && !me?.mobileVerification) {
              dispatch(niceModalFetched({ show: true }));
            }
          }}
        >
          업로드
        </span>
      )}

      {isLogin ? (
        <div className="cursor-pointer">
          <ProfileAvatarMenu />
        </div>
      ) : (
        <Link to="/login" className="flex items-center subtitle-3 ">
          로그인
        </Link>
      )}
    </div>
  );

  const { mutate } = useMutation({
    mutationFn: signOutLogin,
    onSuccess: (data) => {
      if (data?.success) {
        signOut({ dispatch: dispatch });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <>
      <div className="w-1/2">
        <div className="">
          <div className="flex justify-between items-start">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setMobileModal({ show: true })}
            >
              <Avatar
                variant="circular"
                alt="profile img"
                src={
                  me?.profileImage
                    ? me?.profileImage
                    : "/img/standbuy/icon/no-profile.svg"
                }
                placeholder={undefined}
                className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5"
                size="sm"
              />

              <div className="flex ml-4 flex-col">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <StockText
                      fontSize={16}
                      fontWeight={600}
                      className="truncate max-w-[90px] dark:text-darkText"
                    >
                      {me?.nickname || "로그인이 필요합니다."}
                    </StockText>
                  </div>
                  {isLogin && (
                    <StockText
                      fontSize={12}
                      fontWeight={600}
                      color="#737373"
                      className="truncate"
                    >
                      {checkRole(me?.grade) === "아티스트"
                        ? "아티스트"
                        : "디렉터"}
                    </StockText>
                  )}
                </div>
              </div>
            </div>
            {isLogin && (
              <img
                src={"/img/standbuy/icon/logout-icon-2.png"}
                alt={""}
                width={20}
                className="cursor-pointer"
                onClick={() => {
                  if (isLogin) {
                    mutate({
                      accessToken: accessToken,
                      refreshToken: refreshToken,
                      browser: browserData,
                    });
                  }
                }}
              />
            )}
          </div>
          <div className="h-[1px] bg-[#dbdbdb] w-full my-3"></div>
          <MobileTopSection />
        </div>
      </div>

      {mobileModal.show && (
        <MobileAvatarModal
          open={mobileModal.show}
          onClose={() => setMobileModal({ show: false })}
          navList={navList}
        />
      )}
    </>
  );
}
