import React from "react";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";
import NewSectionOne from "./card/new/NewSectionOne";
import NewSectionTwo from "./card/new/NewSectionTwo";
import NewSectionThree from "./card/new/NewSectionThree";
import NewSectionFour from "./card/new/NewSectionFour";
import { useMediaQuery } from "react-responsive";
import { CustomNavBar } from "../../components/layout/CustomNavBar";

export default function NewLandingPage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <>
      <MetaDatas siteName={""} title={"스탠바이"} siteUrl={location.pathname} />
      <CustomNavBar />
      <div className={`${isMobile ? "bg-white" : "bg-[#F2F4F6]"}`}>
        <NewSectionOne />
        <NewSectionTwo />
        <NewSectionThree />
        <NewSectionFour />
      </div>
    </>
  );
}
