//@ts-nocheck
import React, { useState } from "react";
import Text from "../../../../components/design-system/Text";
import FillButton from "../../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import Slider from "react-slick";

export default function NewSectionOne() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hoveredButton, setHoveredButton] = useState("buyer");
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    initialSlide: 0,
  };

  return (
    <div
      className={`min-h-screen flex flex-col items-center justify-center overflow-hidden ${isMobile ? "pt-[60px]" : "pt-[120px]"}`}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.2 }, // 자식 요소들이 0.2초 간격으로 자연스럽게 등장
          },
        }}
      >
        <div className={`flex ${isMobile ? "flex-col gap-10" : "gap-40"}`}>
          <div className={"flex flex-col mx-8"}>
            {!isMobile && (
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.5, ease: "easeOut" },
                  },
                }}
              >
                <img
                  src="/img/standbuy/logo/logo.svg"
                  alt=""
                  width={isMobile ? 180 : 270}
                  className={`${isMobile ? "mb-4" : "mb-8"}  `}
                />
              </motion.div>
            )}

            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.4, ease: "easeOut" },
                },
              }}
            >
              <Text
                fontSize={isMobile ? 40 : 80}
                fontWeight={isMobile ? 900 : 800}
              >
                사진영상 사고파는
              </Text>
            </motion.div>

            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.4, ease: "easeOut" },
                },
              }}
            >
              <Text
                fontSize={isMobile ? 40 : 80}
                fontWeight={isMobile ? 900 : 800}
              >
                스탠바이
              </Text>
            </motion.div>

            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.4, ease: "easeOut" },
                },
              }}
            >
              <div className={`${isMobile ? "mt-6" : "mt-10"}`}>
                <Text
                  fontSize={isMobile ? 18 : 30}
                  fontWeight={600}
                  color="#A3A3A3"
                >
                  핸드폰 속 사진 영상으로 돈도 벌고
                </Text>
                <Text
                  fontSize={isMobile ? 18 : 30}
                  fontWeight={600}
                  color="#A3A3A3"
                >
                  한국 아시아 스톡 콘텐츠도 저렴하게 사고!
                </Text>
              </div>
            </motion.div>

            {/* 버튼 */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.5, ease: "easeOut" },
                },
              }}
            >
              <div className={`${isMobile ? "mt-8" : "mt-12"} flex gap-6`}>
                <FillButton
                  onClick={() => navigate("/feed")}
                  text="사러가기"
                  size="lg"
                  onMouseEnter={() => setHoveredButton("buyer")}
                  color={
                    isMobile
                      ? currentSlide === 0
                        ? undefined
                        : "white"
                      : hoveredButton === "buyer"
                        ? undefined
                        : "white"
                  }
                />

                <FillButton
                  onClick={() => navigate("/login")}
                  text="팔러가기"
                  size="lg"
                  onMouseEnter={() => setHoveredButton("seller")}
                  color={
                    isMobile
                      ? currentSlide === 1
                        ? undefined
                        : "white"
                      : hoveredButton === "seller"
                        ? undefined
                        : "white"
                  }
                />
              </div>
            </motion.div>
          </div>

          {isMobile ? (
            <div className="w-screen ">
              <Slider
                {...settings}
                afterChange={(index) => setCurrentSlide(index)}
              >
                <img
                  src={"/img/standbuy/landing/new/landing-seller.gif"}
                  alt="Seller"
                  width={600}
                  // className="rounded-2xl"
                />

                <img
                  src={"/img/standbuy/landing/new/landing-buyer.gif"}
                  alt="Buyer"
                  width={600}
                  // className="rounded-2xl"
                />
              </Slider>
            </div>
          ) : (
            <motion.div
              className=""
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.7, ease: "easeOut" },
                },
              }}
            >
              <img
                src={
                  hoveredButton === "seller"
                    ? "/img/standbuy/landing/new/landing-seller.gif"
                    : "/img/standbuy/landing/new/landing-buyer.gif"
                }
                alt=""
                width={600}
                className="rounded-2xl"
              />
            </motion.div>
          )}
        </div>
        <motion.div
          className={`flex justify-center ${isMobile ? "mt-10" : "mt-20"} mx-4`}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 0.7, ease: "easeOut" },
            },
          }}
        >
          <video
            className="rounded-2xl w-full"
            src={`${isMobile ? "/img/standbuy/landing/new/landing-mobile.mp4" : "/img/standbuy/landing/new/landing.mp4"}`}
            // width={1200}
            autoPlay
            loop
            muted
            playsInline
          />
        </motion.div>
      </motion.div>
    </div>
  );
}
