import Text from "../../../../components/design-system/Text";
import styled from "styled-components";
import MobileFooter from "../../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const SnsText = styled.span`
  background: linear-gradient(
    to right,
    #000000,
    #b60385,
    #e07eb8,
    #f02c59,
    #f48291
  );
  -webkit-background-clip: text;
  color: transparent;

  font-weight: 800;
`;

export default function NewSectionFour() {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { ref, inView } = useInView({
    threshold: 0.4,
    // rootMargin: "-100px 0px",
    triggerOnce: true,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  return (
    <div
      ref={ref}
      className={`h-9/10 flex flex-col items-center justify-between ${isMobile ? "pt-20" : "pt-20"}  overflow-x-hidden`}
    >
      <motion.div
        initial="hidden"
        animate={controls} // ✅ 애니메이션 제어
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div
          className={`${!isMobile && "min-w-[1340px]"} flex flex-col justify-between items-center`}
        >
          <motion.div
            className="flex justify-center flex-col items-center"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.5, ease: "easeOut" },
              },
            }}
          >
            <Text fontSize={isMobile ? 24 : 60} fontWeight={700}>
              STEP3 내 스튜디오 콘텐츠 확인
            </Text>
            <SnsText className={`${isMobile ? "text-[36px]" : "text-[82px]"}`}>
              SNS 인터페이스로 소통
            </SnsText>
            <div className="mt-10 flex flex-col items-center justify-center">
              <Text fontSize={isMobile ? 20 : 30} fontWeight={700}>
                여느 SNS와 같이 소통하는 대신
              </Text>
              <Text fontSize={isMobile ? 20 : 30} fontWeight={700}>
                우리의 콘텐츠는 모두 가치가 매겨집니다.
              </Text>
            </div>
          </motion.div>

          <motion.div
            variants={{
              hidden: { opacity: 0, y: 40 },
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.4, ease: "easeOut" },
              },
            }}
          >
            <img
              src="/img/standbuy/landing/new/section-four.png"
              alt=""
              width={1200}
              className="mb-[100px]"
            />
          </motion.div>
        </div>
      </motion.div>
      {isMobile ? <MobileFooter /> : <CustomFooter styles={"mt-[140px]"} />}
    </div>
  );
}
