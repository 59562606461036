//@ts-nocheck
import { useNavigate } from "react-router-dom";
import Text from "../../design-system/Text";
import { FooterTextColor } from "../../../styles/color";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState } from "react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";

function Icon({ id, open }) {
  return (
    <svg
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="#838383"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default function CustomFooter({ styles }: any) {
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const [open, setOpen] = useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  return (
    <footer
      className={`${styles} lg:px-12 max-h-[160px] bg-[#F8F8F8] flex w-full border-t border-blue-gray-50 py-2 text-center dark:bg-darkBg dark:!border-[#1a1a1a] transition duration-700 ease-out`}
    >
      <div className="flex flex-col">
        <div className="flex gap-12 mt-4">
          <img
            src={"/img/standbuy/logo/logo-gray.svg"}
            alt="스탠바이"
            onClick={() => navigate("/")}
            width={150}
            className="cursor-pointer"
          />
          <div
            className={`flex items-center gap-4 ${
              themeData === "dark" ? "" : "divide-x-2"
            }`}
          >
            <Text
              className="profile-menu-text cursor-pointer "
              color={FooterTextColor}
              onClick={() => navigate("/")}
            >
              스탠바이
            </Text>
            <Text
              className="profile-menu-text cursor-pointer pl-4 "
              color={FooterTextColor}
              onClick={() => navigate("/notice")}
            >
              공지사항
            </Text>
            <Text
              className="profile-menu-text pl-4 cursor-pointer "
              color={FooterTextColor}
              onClick={() => navigate("/terms")}
            >
              이용약관
            </Text>
            <Text
              fontWeight={700}
              className="profile-menu-text pl-4 cursor-pointer "
              color={FooterTextColor}
              onClick={() => navigate("/policy")}
            >
              개인정보 처리방침
            </Text>{" "}
            <Text
              className="profile-menu-text pl-4 cursor-pointer "
              color={FooterTextColor}
              onClick={() => navigate("/location-terms")}
            >
              위치기반서비스 이용약관
            </Text>
            {/* <Text
              className="profile-menu-text pl-4 cursor-pointer"
              color={FooterTextColor}
            >
              아티스트 약관
            </Text>
            <Text
              className="profile-menu-text pl-4 cursor-pointer"
              color={FooterTextColor}
            >
              튜토리얼
            </Text> */}
          </div>
        </div>

        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="!justify-start border-none"
          >
            <Text className="profile-menu-text " color={FooterTextColor}>
              STAND BUY 사업자 정보
            </Text>
          </AccordionHeader>
          <AccordionBody className="py-0">
            <div
              className={`flex items-center gap-4 ${
                themeData === "dark" ? "" : "divide-x-2"
              }`}
            >
              <Text className="profile-menu-text " color={FooterTextColor}>
                상호명 : 주식회사 에프오에프
              </Text>
              <Text className="profile-menu-text pl-3 " color={FooterTextColor}>
                사업자등록번호 : 476-81-03372
              </Text>
              <Text className="profile-menu-text pl-3 " color={FooterTextColor}>
                대표자명 : 최재영
              </Text>
              <Text className="profile-menu-text pl-3 " color={FooterTextColor}>
                통신판매업신고 : 제 2024-서울성동-0442호
              </Text>
              <Text className="profile-menu-text pl-3 " color={FooterTextColor}>
                주소 : 서울특별시 성동구 왕십리로 273-1 3층
              </Text>
              <Text
                className="profile-menu-text pl- 3 "
                color={FooterTextColor}
              >
                대표번호 : 02.2282.8766
              </Text>
              <Text className="profile-menu-text pl-3 " color={FooterTextColor}>
                이메일 : fof@frameofframe.com
              </Text>
            </div>
          </AccordionBody>

          {/* <AccordionBody className="py-0 mt-2 pb-2">
            <div
              className={`flex items-center gap-4 ${
                themeData === "dark" ? "" : "divide-x-2"
              }`}
            >
              <Text className="profile-menu-text" color={FooterTextColor}>
                상호명 : 프레임오브프레임
              </Text>
              <Text className="profile-menu-text pl-3" color={FooterTextColor}>
                사업자등록번호 : 154-01-02054
              </Text>
              <Text className="profile-menu-text pl-3" color={FooterTextColor}>
                대표자명 : 최재영
              </Text>
              <Text className="profile-menu-text pl-3" color={FooterTextColor}>
                통신판매업신고 : 제 2024-서울성동-0442 호
              </Text>
              <Text className="profile-menu-text pl-3" color={FooterTextColor}>
                주소 : 서울특별시 성동구 왕십리로 273-1 3층
              </Text>
              <Text className="profile-menu-text pl-3" color={FooterTextColor}>
                대표번호 : 02.2282.8766
              </Text>
            </div>
          </AccordionBody> */}
        </Accordion>
      </div>
    </footer>
  );
}
