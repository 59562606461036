import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";

export default function NewSectionThree() {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { ref, inView } = useInView({
    threshold: 0.7,
    // rootMargin: "-100px 0px",
    triggerOnce: true,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  return (
    <div
      ref={ref}
      className={`h-9/10 overflow-x-hidden  flex flex-col items-center justify-between ${isMobile ? "" : "pb-40"} ${
        isMobile && "mx-4"
      }`}
    >
      <motion.div
        initial="hidden"
        animate={controls} // ✅ 애니메이션 제어
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div
          className={`${!isMobile && "min-w-[1340px]"} flex flex-col justify-between`}
        >
          <div className={`flex flex-col`}>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.5, ease: "easeOut" },
                },
              }}
            >
              <img
                src="/img/standbuy/landing/new/section-three-top.png"
                alt=""
                className={`${!isMobile && "max-w-[1340px]"}`}
                // width={1200}
              />
            </motion.div>
          </div>
          <div className={`mt-2 ${!isMobile && "gap-4"} flex w-full`}>
            <motion.div
              className="w-full"
              initial={{ opacity: 0, x: -200 }} // 왼쪽에서 시작
              animate={controls} // ✅ 애니메이션 제어
              variants={{
                hidden: { opacity: 0, x: -200 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1, ease: "easeOut" },
                },
              }}
            >
              <img
                src="/img/standbuy/landing/new/section-three-bottom-left.png"
                alt=""
                width={670}
              />
            </motion.div>
            <motion.div
              className="w-full"
              initial={{ opacity: 0, x: 200 }} // 왼쪽에서 시작
              animate={controls} // ✅ 애니메이션 제어
              variants={{
                hidden: { opacity: 0, x: 200 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1, ease: "easeOut" },
                },
              }}
            >
              <img
                src="/img/standbuy/landing/new/section-three-bottom-right.png"
                alt=""
                width={660}
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
