import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import AvatarCard from "./card/AvatarCard";
import ProfileCard from "./card/ProfileCard";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "../../util/useContext";
import StudioFeedCard from "./card/StudioFeedCard";
import { FeedNav } from "../../components/design-system/FeedNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";
import StudioMobilePage from "./mobile/StudioMobilePage";
import { profileStudio } from "../../api/studio/studio";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";

export default function StudioPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state, me } = useContext();
  const location = useLocation();

  const { data } = useQuery({
    queryKey: ["profile"],
    queryFn: profileStudio,
  });

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <MetaDatas
        title={"마이 스튜디오"}
        siteName={""}
        siteUrl={location.pathname}
      />

      {isMobile ? (
        <StudioMobilePage />
      ) : (
        <div className="flex flex-col justify-between h-full">
          <motion.div
            className={`w-full flex justify-center mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="mt-[120px] mx-[200px] w-full max-w-[1280px]">
              <div className="flex justify-between items-center mb-[30px]">
                <Text className="title ">내 스튜디오</Text>
              </div>

              <div className="flex gap-6">
                <AvatarCard data={data?.data} />
                <ProfileCard data={data?.data} />
              </div>

              <StudioFeedCard />
            </div>

            <div
              className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
            >
              <div className="flex flex-col justify-center items-center gap-4">
                <FeedNav />

                {!isMobile && (
                  <IconButton
                    //@ts-ignore
                    className="rounded-full px-[28px] py-[28px]"
                    placeholder={undefined}
                    children={undefined}
                    size="lg"
                    onClick={moveToTop}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </IconButton>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}
