import Text from "../design-system/Text";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../store/store";
import { useContext } from "../../util/useContext";
import { styled } from "styled-components";

const Wrap = styled.div<any>`
  position: relative;
  width: 50%;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
    border-radius: 12px;
    width: 100%;
  }
`;

export default function DirectorBannerMobile() {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const { me, isLogin } = useContext();

  return (
    <Wrap className="relative w-1/2">
      <img
        src="/img/standbuy/background/director-bg.png"
        alt="director-bg"
        className="h-[130px] w-full object-cover cursor-pointer rounded-xl"
        onLoad={() => setIsLoaded(true)}
      />
      <div className="absolute bottom-4 left-3 flex flex-col z-20">
        <div className="rounded-full bg-[#FF9B19]  mb-1 text-center w-[100px]">
          <Text color="#fff" fontWeight={600} fontSize={12}>
            25.02 UPDATE
          </Text>
        </div>

        <Text className="" fontSize={14} color="#ffffff" fontWeight={900}>
          1년 이용권 → 이용권 변경!
        </Text>
        <Text className="" fontSize={7} color="#ffffff" fontWeight={400}>
          *자세한 내용은 이용약관을 통해 확인이 가능합니다.
        </Text>
      </div>
    </Wrap>
  );
}
