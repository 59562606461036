import { Button } from "@material-tailwind/react";
import Text from "../Text";

export default function FillButton({
  size = "md",
  text,
  disabled = false,
  onClick,
  className,
  color = "green",
  loading,
  fontWeight = 700,
  fontSize = 16,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  return (
    <Button
      loading={loading}
      className={`${
        color === "red" ||
        color === "white" ||
        color === "gray" ||
        color === "black"
          ? ""
          : "bg-[#134B70] shadow-[#396984] hover:shadow-[#396984]"
      } + ${className} + disabled:opacity-70 `}
      size={size}
      variant="filled"
      color={color}
      disabled={disabled}
      onClick={onClick}
      placeholder={undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span
        color={color === "white" ? "black" : "white"}
        className="text-[16px]"
      >
        {text}
      </span>
    </Button>
  );
}

interface Props {
  loading?: boolean;
  width?: string;
  size?: "sm" | "md" | "lg";
  text?: any;
  disabled?: boolean;
  onClick: any;
  className?: string;
  fontWeight?: number;
  fontSize?: number;
  onMouseEnter?: any;
  onMouseLeave?: any;
  color?:
    | "white"
    | "black"
    | "blue-gray"
    | "gray"
    | "brown"
    | "deep-orange"
    | "orange"
    | "amber"
    | "yellow"
    | "lime"
    | "light-green"
    | "green"
    | "teal"
    | "cyan"
    | "light-blue"
    | "blue"
    | "indigo"
    | "deep-purple"
    | "purple"
    | "pink"
    | "red";
}
