import Text from "../design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import React, { useState } from "react";
import CartEndModal from "../../pages/cart/modal/CartEndModal";
import StandbuyModal from "./StandbuyModal";
import { modalFetched } from "../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../store/store";

export default function MobileTopSection() {
  const navigate = useNavigate();
  const { isLogin } = useContext();
  const dispatch = useAppDispatch();
  const [videoModal, setVideoModal] = useState<any>({
    show: false,
    data: "",
  });
  return (
    <>
      <div className="flex justify-evenly gap-4">
        {/* <div
          className="flex flex-col items-center gap-2"
          onClick={() => navigate("/locker")}
        >
          <div
            className={
              "w-[32px] h-[32px]  rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img
              src={"/img/standbuy/icon/main/standbuy.svg"}
              alt="locker"
              width={"24"}
            />
          </div>
          <Text fontWeight={400} fontSize={8}>
            스탠바이란?
          </Text>
        </div> */}

        <div
          className="flex flex-col items-center gap-2"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else {
              navigate(`/locker`);
            }
          }}
        >
          <div
            className={
              "w-[40px] h-[40px]  rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img
              src="/img/standbuy/icon/main/locker.png"
              alt="locker"
              width={"32"}
            />
          </div>
          <Text fontWeight={700} fontSize={8}>
            보관함
          </Text>
        </div>
        <div
          className="flex flex-col items-center gap-2"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else {
              navigate(`/cart`);
            }
          }}
        >
          <div
            className={
              "w-[40px] h-[40px] rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img
              src="/img/standbuy/icon/main/cart.png"
              alt="cart"
              width={"32"}
            />
          </div>
          <Text fontWeight={700} fontSize={8}>
            장바구니
          </Text>
        </div>

        <div
          className="flex flex-col items-center gap-2"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else {
              navigate(`/my-studio`);
            }
          }}
        >
          <div
            className={
              "w-[40px] h-[40px]  rounded-full bg-[#EDEDED] flex items-center justify-center"
            }
          >
            <img
              src="/img/standbuy/icon/main/studio.png"
              alt="studio"
              width={"32"}
            />
          </div>
          <Text fontWeight={700} fontSize={8}>
            내 스튜디오
          </Text>
        </div>
      </div>
      {videoModal.show && (
        <StandbuyModal
          open={videoModal.show}
          onClose={() => setVideoModal({ show: false })}
          data={videoModal.data}
        />
      )}
    </>
  );
}
