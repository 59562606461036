import { Avatar } from "@material-tailwind/react";
import Text from "../design-system/Text";
import { useContext } from "../../util/useContext";
import { checkRole } from "../../util";
import { signOut } from "../../lib/modules/auth";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { shallowEqual } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { signOutLogin } from "../../api/login/login";
import { toastFetched } from "../../store/reducer/toastSlice";
import StockText from "../design-system/StockText";
import { useNavigate } from "react-router-dom";
import { addComma } from "../../util/number";
import { useMediaQuery } from "react-responsive";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";

export default function BoxAvatarCard() {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { me, isLogin } = useContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );

  const { mutate } = useMutation({
    mutationFn: signOutLogin,
    onSuccess: (data) => {
      if (data?.success) {
        signOut({ dispatch: dispatch });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error?.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <div className="w-[690px] max-w-[690px] h-[200px] border border-[#dbdbd] rounded-xl relative">
      {isLogin && (
        <div className="absolute right-8 top-8">
          <img
            src={
              themeData === "dark"
                ? "/img/standbuy/icon/logout-icon-2-white.png"
                : "/img/standbuy/icon/logout-icon-2.png"
            }
            alt={""}
            width={themeData === "dark" ? 20 : 28}
            className="cursor-pointer"
            onClick={() => {
              if (isLogin) {
                mutate({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                  browser: browserData,
                });
              }
            }}
          />
        </div>
      )}

      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-start ml-8 mt-10">
          <div className="flex justify-between items-center">
            <Avatar
              variant="circular"
              alt="profile img"
              src={
                me?.profileImage
                  ? me?.profileImage
                  : "/img/standbuy/icon/no-profile.svg"
              }
              placeholder={undefined}
              className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5"
              size="xl"
            />

            <div className="flex ml-6 flex-col">
              <div className="flex gap-2 items-center">
                <StockText
                  fontSize={18}
                  fontWeight={600}
                  className={`${isLogin && "truncate max-w-[140px]"} dark:text-darkText`}
                >
                  {me?.nickname || "로그인이 필요합니다."}
                </StockText>
                {isLogin && (
                  <StockText
                    fontSize={18}
                    fontWeight={600}
                    color="#737373"
                    className="truncate"
                  >
                    {checkRole(me?.grade) === "아티스트"
                      ? "아티스트"
                      : "디렉터"}
                  </StockText>
                )}
              </div>

              {isLogin && (
                <div className="flex items-center gap-4 mt-1">
                  <Text
                    className="cursor-pointer "
                    fontWeight={600}
                    fontSize={15}
                    onClick={() => navigate("/fof-credit")}
                  >
                    수익금{" "}
                    <Text span fontWeight={800} color="#244A6D" fontSize={15}>
                      {addComma(me?.profit) || "0"}원
                    </Text>
                  </Text>

                  <div className="h-[20px] bg-[#dbdbdb] w-[0.5px]"></div>

                  <Text className="" fontWeight={600} fontSize={15}>
                    구독자{" "}
                    <Text span fontWeight={800} color="#244A6D" fontSize={15}>
                      {addComma(me?.subscriberCount) || "0"}
                    </Text>
                  </Text>

                  <div className="h-[20px] bg-[#dbdbdb] w-[0.5px]"></div>

                  <Text className="" fontWeight={600} fontSize={15}>
                    콘텐츠{" "}
                    <Text span fontWeight={800} color="#244A6D" fontSize={15}>
                      {addComma(me?.upCount) || "0"}
                    </Text>
                  </Text>
                </div>
              )}

              {/* <div className="flex gap-2 mt-2">
                <Text
                  fontSize={12}
                  fontWeight={700}
                  color="#244A6D"
                  className="underline underline-offset-4 cursor-pointer "
                >
                  프로필 수정
                </Text>
              </div> */}
            </div>

            {/* <div className="ml-4">
              <button
                className="rounded-full bg-[#134B70] py-2 px-4 opacity-1 cursor-pointer"
                onClick={() => {
                  if (isLogin) {
                    mutate({
                      accessToken: accessToken,
                      refreshToken: refreshToken,
                      browser: browserData,
                    });
                  }
                }}
              >
                <Text fontWeight={600} color="white">
                  로그아웃
                </Text>
              </button>
            </div> */}
          </div>
        </div>

        <div className="h-[60px] flex justify-evenly items-center border border-t-1 border-l-0 border-r-0 border-b-0 ">
          <Text
            className="cursor-pointer hover:text-[#134B70] hover:underline  hover:underline-offset-4 "
            fontWeight={600}
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/my-studio`);
              }
            }}
          >
            내 스튜디오
          </Text>
          <div className="h-[30px] bg-[#dbdbdb] w-[0.5px]"></div>

          <Text
            className="cursor-pointer hover:text-[#134B70] hover:underline  hover:underline-offset-4 "
            fontWeight={600}
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/locker`);
              }
            }}
          >
            보관함
          </Text>
          <div className="h-[30px] bg-[#dbdbdb] w-[0.5px]"></div>
          <Text
            className="cursor-pointer hover:text-[#134B70] hover:underline  hover:underline-offset-4 "
            fontWeight={600}
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/my-subs`);
              }
            }}
          >
            구독
          </Text>
          <div className="h-[30px] bg-[#dbdbdb] w-[0.5px]"></div>

          <Text
            className="cursor-pointer hover:text-[#134B70] hover:underline  hover:underline-offset-4 "
            fontWeight={600}
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/my-page`);
              }
            }}
          >
            마이페이지
          </Text>
        </div>
      </div>
    </div>
  );
}
